input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

:root {
  text-align: center;
  color: #eee;
  background-color: #000;
  height: 100%;
  box-sizing: border-box;
  font-family: "Carter One", serif;
}

html,
body {
  margin: 0;
  position: relative;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

header {
  height: 50px;
}

footer .controls button img {
  height: 50px;
  width: 50px;
}

.icon-team > img {
  height: 100px;
  height: 100px;
  margin: 10px;
}

.total-score,
.pool,
.controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.score-team,
.pool .player {
  max-width: 100px;
}

.pool .player .name {
  height: 50px;
}

.pool .player .image img {
  max-height: 80px;
  margin: 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 5px solid transparent;
  cursor: pointer;
}

img.table-image {
  max-height: 30px;
  margin: 0px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.pool .player .image.selected img {
  border: 5px solid rgb(77, 148, 77);
}

.pool .player .tie {
  margin-top: 50px;
}

.pool .player .tie svg {
  max-height: 80px;
  width: 50px;
  margin: 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 5px solid transparent;
  cursor: pointer;
}

.pool .player .tie svg path {
  fill: #343434;
}

.pool .player .tie svg:hover {
  fill: rgb(77, 148, 77);
}

.pool .player .tie.selected svg path {
  fill: rgb(77, 148, 77);
}

.section:first-child {
  border: none;
  padding-bottom: 20px;
}

.section {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
}

main {
  margin-bottom: 70px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  background-color: #343434;
}

footer .controls button {
  font-size: 40px;
  filter: brightness(80%);
  border: none;
  background-color: transparent;
}

footer .controls button:hover {
  filter: brightness(100%);
}

footer > div > button.next > img {
  fill: blue;
}

.hole {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #343434;
  display: flex;
}

.hole {
  display: flex;
  align-items: center;
}

.hole p {
  width: 100%;
  text-align: center;
  font-size: 170%;
  margin: 0;
}

.section:first-child {
  margin-top: 50px;
}

.setup-holes {
  width: 100%;
}

form {
  text-align: left;
  padding: 10px;
}

label {
  display: block;
}

input {
  width: 100%;
}

button {
  width: 33.33%;
  margin: 0;
}

.players-list {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
}

.btn {
  margin: 10px;
  padding: 20px;
  background-color: #555;
  border-radius: 10px;
  cursor: pointer;
}

a {
  color: #eee;
  text-decoration: none;
}

.btn > a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
